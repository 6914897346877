import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/usr/src/app/workspaces/extie/src/templates/help-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AddCommandTag = makeShortcode("AddCommandTag");
const GenericCommandTag = makeShortcode("GenericCommandTag");
const GetGlobalTag = makeShortcode("GetGlobalTag");
const SetGlobalTag = makeShortcode("SetGlobalTag");
const PronounTag = makeShortcode("PronounTag");
const GenericGlobalTag = makeShortcode("GenericGlobalTag");
const ShowExitTag = makeShortcode("ShowExitTag");
const GenericLocationTag = makeShortcode("GenericLocationTag");
const ShowCommandTag = makeShortcode("ShowCommandTag");
const IfTag = makeShortcode("IfTag");
const EndIfTag = makeShortcode("EndIfTag");
const IfVisitedTag = makeShortcode("IfVisitedTag");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tag Reference`}</h1>
    <p>{`| `}<a parentName="p" {...{
        "href": "#command-tags"
      }}>{`Command Tags`}</a>{` |
| `}<a parentName="p" {...{
        "href": "#global-tags"
      }}>{`Global Tags`}</a>{` |
| `}<a parentName="p" {...{
        "href": "#location-tags"
      }}>{`Location Tags`}</a>{` |
| `}<a parentName="p" {...{
        "href": "#condition-tags"
      }}>{`Condition Tags`}</a>{` |`}</p>
    <h3>{`Command Tags`}</h3>
    <ul>
      <li parentName="ul">
        <AddCommandTag mdxType="AddCommandTag" />
      </li>
    </ul>
    <p>{`Adds option with text that runs the selected page/message.`}</p>
    <ul>
      <li parentName="ul">
        <GenericCommandTag mdxType="GenericCommandTag">Add Persistent Command</GenericCommandTag>
      </li>
    </ul>
    <p>{`Like `}<AddCommandTag mdxType="AddCommandTag" />{` but the option will not be removed after the player chooses it.`}</p>
    <ul>
      <li parentName="ul">
        <GenericCommandTag mdxType="GenericCommandTag">Add Location Command</GenericCommandTag>
      </li>
    </ul>
    <p>{`Adds option with text that sends player to the selected location. `}</p>
    <ul>
      <li parentName="ul">
        <GenericCommandTag mdxType="GenericCommandTag">Remove Command</GenericCommandTag>
      </li>
    </ul>
    <p>{`Removes option.`}</p>
    <p><a parentName="p" {...{
        "href": "#tag-reference"
      }}>{`Back to top`}</a></p>
    <h3>{`Global Tags`}</h3>
    <ul>
      <li parentName="ul">
        <GetGlobalTag mdxType="GetGlobalTag" />
      </li>
    </ul>
    <p>{`Replaced by the current value of the selected global.`}</p>
    <ul>
      <li parentName="ul">
        <SetGlobalTag mdxType="SetGlobalTag" />
      </li>
    </ul>
    <p>{`Sets the selected global to the entered value.`}</p>
    <ul>
      <li parentName="ul">
        <PronounTag mdxType="PronounTag" />
      </li>
    </ul>
    <p>{`Replaced by the player's chosen pronoun. `}</p>
    <ul>
      <li parentName="ul">
        <GenericGlobalTag mdxType="GenericGlobalTag">+1</GenericGlobalTag>
      </li>
    </ul>
    <p>{`Adds one to the selected global.`}</p>
    <ul>
      <li parentName="ul">
        <GenericGlobalTag mdxType="GenericGlobalTag">-1</GenericGlobalTag>
      </li>
    </ul>
    <p>{`Subtracts one from the selected global.`}</p>
    <p><a parentName="p" {...{
        "href": "#tag-reference"
      }}>{`Back to top`}</a></p>
    <h3>{`Location Tags`}</h3>
    <ul>
      <li parentName="ul">
        <ShowExitTag mdxType="ShowExitTag" />
      </li>
    </ul>
    <p>{`Shows the selected exit to the player.`}</p>
    <ul>
      <li parentName="ul">
        <GenericLocationTag mdxType="GenericLocationTag">Hide Exit</GenericLocationTag>
      </li>
    </ul>
    <p>{`Hides the selected exit from the player.`}</p>
    <ul>
      <li parentName="ul">
        <ShowCommandTag mdxType="ShowCommandTag" />
      </li>
    </ul>
    <p>{`Shows the selected location-based command to the player.`}</p>
    <ul>
      <li parentName="ul">
        <GenericLocationTag mdxType="GenericLocationTag">Hide Command</GenericLocationTag>
      </li>
    </ul>
    <p>{`Hides the selected location-based command from the player.`}</p>
    <ul>
      <li parentName="ul">
        <GenericLocationTag mdxType="GenericLocationTag">Remove Location</GenericLocationTag>
      </li>
    </ul>
    <p>{`Removes player's location completely.`}</p>
    <p><a parentName="p" {...{
        "href": "#tag-reference"
      }}>{`Back to top`}</a></p>
    <h3>{`Condition Tags`}</h3>
    <ul>
      <li parentName="ul">
        <IfTag mdxType="IfTag" />
      </li>
    </ul>
    <p>{`Will only run the text between it and the `}<EndIfTag mdxType="EndIfTag" />{` if the specified condition is true. Must be accompanied by an `}<EndIfTag mdxType="EndIfTag" />{`.  `}</p>
    <ul>
      <li parentName="ul">
        <IfVisitedTag mdxType="IfVisitedTag" />
      </li>
    </ul>
    <p>{`Will only run the trext between it and the `}<EndIfTag mdxType="EndIfTag" />{` if the specified location has /has not been visited by the player.  Must be accompanied by an `}<EndIfTag mdxType="EndIfTag" />{`.`}</p>
    <ul>
      <li parentName="ul">
        <EndIfTag mdxType="EndIfTag" />
      </li>
    </ul>
    <p>{`Closes a conditional block.  Conditions can be nested but each one must be closed by an `}<EndIfTag mdxType="EndIfTag" />{`.`}</p>
    <p><a parentName="p" {...{
        "href": "#tag-reference"
      }}>{`Back to top`}</a></p>
    <p><a parentName="p" {...{
        "href": "/help"
      }}>{`Back to help`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      