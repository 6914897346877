import React from "react";
import { MDXProvider } from "@mdx-js/react";
import { Helmet } from "react-helmet";
import YouTube from "react-youtube";

import ArgieGlobalStyles from "fargie/js/components/templates/argie-global-styles";
import Template from "../templates/index";
import { tags } from "./tags";
import { VideoLoop } from "atoms/VideoLoop";
import {
  Box,
  Button,
  Container,
  ChakraProvider,
  Flex,
  Heading,
  Img,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useTheme
} from "@chakra-ui/react";

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

const components = {
  YouTube,
  VideoLoop: props => <VideoLoop {...props} my="30px" />,
  Box,
  Button,
  Link,
  Text,
  inlineCode: props => <Text as="span" textStyle="code" {...props} />,
  h1: props => (
    <a id={slugify(props.children)}>
      <Heading as="h1" mb="10" size="3xl" {...props} />
    </a>
  ),
  h2: props => (
    <a id={slugify(props.children)}>
      <Heading as="h2" mt={20} {...props} />
    </a>
  ),
  h3: props => (
    <a id={slugify(props.children)}>
      <Heading
        as="h3"
        mb={4}
        size="lg"
        fontWeight="normal"
        mt={16}
        {...props}
      />
    </a>
  ),
  p: props => <Text textStyle="paragraph" lineHeight="35px" {...props} />,
  ul: props => <Box textStyle="paragraph" mb="0" mt="5" as="ul" {...props} />,
  li: props => <Box as="li" style={{ listStyle: "none" }} {...props} />,
  a: props => <Link textStyle="underline" {...props} />,
  strong: props => (
    <Text
      as="span"
      bg="primary"
      py="1px"
      px="5px"
      borderRadius={5}
      borderWidth={1}
      borderColor="primary"
      color="white"
      {...props}
    />
  ),
  blockquote: props => (
    <Box backgroundColor="#CCC" color="black" m={3} mb={7} p={3}>
      <Text textStyle="blockquote" {...props} />
    </Box>
  ),
  ...tags
};

export default function Layout({ children, pageContext }) {
  const { frontmatter } = pageContext;
  const { title } = frontmatter;
  return (
    <MDXProvider components={components}>
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <ArgieGlobalStyles />
      <Template bg="white" color="black" fontSize="10pt">
        <Container my="5" maxW="container.md">
          {children}
        </Container>
      </Template>
    </MDXProvider>
  );
}
